<template>
    <div>TEST</div>
 


</template>


<script setup>
    import { ref, computed, watch } from 'vue';
    import ODataLookup from 'o365.vue.components.DataLookup.vue'    
    import { getDataObjectById } from 'o365.vue.ts';
    import { getOrCreateDataObject } from 'o365.vue.ts';


</script>
